<template>
  <b-modal ref="modal-cancel-plan-v3" id="modal-delete-account-v3" centered no-close-on-backdrop hide-header hide-footer>
    <div class="d-flex flex-column">
      <span class="title-modal-change-password mb-3">Delete account permanently?</span>
      <span class="sub-modal-delete-acc mb-32px">You will no longer be able to access your account and all the files will be permanently deleted.</span>
    </div>
    <div class="form-input">
      <b-form-group :invalid-feedback="invalidPassword" :state="statePassword">
        <b-form-input
          v-model="password"
          :type="passwordState"
          class="form-control input-box-v3"
          placeholder="Password"></b-form-input>
        <img v-if="passwordState == 'password'" src="@/assets/images/icon/fi-rr-eye-close.svg" alt="" @click="showPassword()">
        <img v-else src="@/assets/images/icon/fi-rr-eye-open.svg" alt="" @click="showPassword()">
      </b-form-group>
    </div>
    <!-- <b-form action="">
      <b-form-group :invalid-feedback="invalidPassword" :state="statePassword">
        <div class="form-control-encrypt">
          <b-form-input
            id="password-delete-acc"
            placeholder="Password"
            :type="passwordState"
            class="form-input-encrypt"
            v-model.trim="$v.password.$model"
            :class="{'is-invalid':$v.password.$error, 'is-valid':!$v.password.$invalid}"></b-form-input>
          <img v-if="passwordState == 'password'" src="@/assets/images/icon/fi-rr-eye-close.svg" alt="" @click="showPassword()">
          <img v-else src="@/assets/images/icon/fi-rr-eye-open.svg" alt="" @click="showPassword()">
          <div class="invalid-feedback">
              <span v-if="!$v.password.required">Password is required.</span>
          </div>
        </div>
      </b-form-group>
    </b-form> -->
    <div class="d-flex justify-content-end mt-40px">
      <button @click="close()" class="btn-keep-plan-v3 main-color-api">Cancel</button>
      <button :disabled="password == ''" @click="submitDelete()" class="ml-3 btn-submit-delete-acc main-btn-negative">Delete account</button>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'ModalDeleteAccount',
  data() {
    return {
      password: '',
      passwordState: 'password',
      invalidPassword: null,
      statePassword: true
    }
  },
  methods: {
    close() {
      this.$ga.event(process.env.VUE_APP_GA_SET_DEL_ACCOUNT_CANCEL, 'Settings - Click Button Cancel in Form Del Account', 'Settings Primary')
      this.$bvModal.hide('modal-delete-account-v3')
      this.password = ''
    },
    submitDelete() {
      this.$ga.event(process.env.VUE_APP_GA_SET_DEL_ACCOUNT_OK, 'Settings - Click Button Delete in Form Del Account', 'Settings Primary')
      this.$store.dispatch('user/deleteAccount', { password: this.password})
      .then((res) => {
        this.$bvModal.hide('modal-delete-account-v3')
        this.password = ''
        this.invalidPassword = null
      })
      .catch((err) => {
        var deleteError = err.data.display_message
        this.invalidPassword = deleteError.toLowerCase() == 'wrong password' ? 'Incorrect password. Please check it again or try another password. ' : ''
        this.statePassword = false
      })
    },
    showPassword() {
      if(this.passwordState === 'password'){
        this.passwordState = 'text'
      } else {
        this.passwordState = 'password'
      }
    },
  }
}
</script>

<style>
.btn-submit-delete-acc{
    height: 40px;
    width: 141px;
    color: #FFFFFF;
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1px;
    border: none;
}
.btn-submit-delete-acc:disabled{
    height: 40px;
    width: 141px;
    background: #E9EAEA;
    color: #919394;
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1px;
    border: none;
}
.btn-keep-plan-v3{
    height: 40px;
    width: 90px;
    background: #FFFFFF;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1px;
    border: none;
}
.btn-keep-plan-v3:focus{
    background: none;
}
.sub-modal-delete-acc{
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -1px;
    color: #262A2C;
    line-height: 20px;
}
.mb-32px{
    margin-bottom: 32px;
}
.mt-40px{
  margin-top: 40px;
}
.form-control-encrypt .is-invalid{
  border: 1px solid #D42020;
}
</style>