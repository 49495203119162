<template>
    <b-modal id="modal-change-password-v3" no-close-on-backdrop centered hide-header hide-footer>
        <p class="mb-4 title-modal-change-password">Change password</p>
        <b-form action="">
            <div class="form-input">
                <b-form-group :invalid-feedback="invalidOldPassword" :state="stateOldPassword">
                    <b-form-input
                        id="password-change-old-v3"
                        v-on:keyup="validatePassword()"
                        v-model.trim="$v.oldPassword.$model"
                        :type="oldPasswordState"
                        class="form-control input-box-v3"
                        :class="{'is-invalid': validationStatus($v.oldPassword)}"
                        placeholder="Old password"></b-form-input>
                    <div class="invalid-feedback">
                        <span v-if="!$v.oldPassword.required" id="password-old-empty" class="required-color error-message-v3">Password cannot be empty.</span>
                    </div>
                    <img v-if="oldPasswordState == 'password'" src="@/assets/images/icon/fi-rr-eye-close.svg" alt="" @click="showOldPassword()">
                    <img v-else src="@/assets/images/icon/fi-rr-eye-open.svg" alt="" @click="showOldPassword()">
                </b-form-group>
            </div>
            <div class="form-input">
                <b-form-group>
                    <b-form-input
                        id="password-change-new-v3"
                        v-on:keyup="validatePassword()"
                        v-model.trim="$v.newPassword.$model"
                        :type="newPasswordState"
                        class="form-control input-box-v3"
                        :class="{'is-invalid': validationStatus($v.newPassword)}"
                        placeholder="Password"></b-form-input>
                    <div class="invalid-feedback">
                        <span v-if="!$v.newPassword.valid" id="password-error" class="required-color error-message-v3"></span>
                        <span v-if="!$v.newPassword.required" id="password-empty" class="required-color error-message-v3">Password cannot be empty.</span>
                        <span v-if="!$v.newPassword.maxLength" id="password-max-length" class="required-color error-message-v3">Password max. length is 128 characters.</span>
                    </div>
                    <img v-if="newPasswordState == 'password'" src="@/assets/images/icon/fi-rr-eye-close.svg" alt="" @click="showNewPassword()">
                    <img v-else src="@/assets/images/icon/fi-rr-eye-open.svg" alt="" @click="showNewPassword()">
                    <div class="row">
                        <div class="col-6 required-password">
                            <ul>
                                <li class="marker-grey" id="lower-char">One lowercase letter</li>
                                <li class="marker-grey" id="upper-char">One uppercase letter</li>
                                <li class="marker-grey" id="number-char">One number</li>
                            </ul>
                        </div>
                        <div class="col-6 required-password">
                            <ul>
                                <li class="marker-grey" id="special-char">One special character</li>
                                <li class="marker-grey" id="minimum-character">8 characters minimum</li>
                            </ul>
                        </div>
                    </div>
                </b-form-group>
            </div>
            <div class="form-input">
                <b-form-group>
                    <b-form-input
                        id="password-confirm-v3"
                        v-on:keyup="validatePassword()"
                        v-model.trim="$v.confirmNewPassword.$model"
                        :type="confirmNewPasswordState"
                        class="form-control input-box-v3"
                        :class="{'is-invalid': validationStatus($v.confirmNewPassword)}"
                        placeholder="Confirm password"></b-form-input>
                    <div class="invalid-feedback">
                        <span v-if="!$v.confirmNewPassword.sameAsPassword" id="password-confirm-error" class="required-color error-message-v3">Password doesn’t match. Please double-check the password and try  again.</span>
                    </div>
                    <img v-if="confirmNewPasswordState == 'password'" src="@/assets/images/icon/fi-rr-eye-close.svg" alt="" @click="showNewPasswordConfirm()">
                    <img v-else src="@/assets/images/icon/fi-rr-eye-open.svg" alt="" @click="showNewPasswordConfirm()">
                </b-form-group>
            </div>
        </b-form>
        <div class="btn-modal-encrypt">
            <button class="mr-3 btn-close-change-pw-v3" @click="close()">Cancel</button>
            <button class="mr-2 btn-change-pw-v3" @click="submitBtn()" :disabled="$v.$invalid">Save</button>
        </div>
    </b-modal>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, sameAs, maxLength, minLength } from 'vuelidate/lib/validators'

export default {
    name: 'ModalChangePassword',
    data() {
        return {
            v$: useVuelidate(),
            stateOldPassword: true,
            invalidOldPassword: '',
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            oldPasswordState: 'password',
            newPasswordState: 'password',
            confirmNewPasswordState: 'password',
            maxPassLength: 18
        }
    },
    validations: {
        oldPassword: {
            required
        },
        newPassword: {
            required,
            minLength: minLength(8),
            maxLength: maxLength(128),
            valid: function(value) {
                const containsUppercase = /[A-Z]/.test(value)
                const containsLowercase = /[a-z]/.test(value)
                const containsNumber = /[0-9]/.test(value)
                const containsSpecial = /[!#@~*&^%)([\]\\+=?<>;:"`$_)-/',.|}{]+/.test(value)
                return containsUppercase && containsLowercase && containsNumber && containsSpecial
            }
        },
        confirmNewPassword: {
            sameAsPassword: sameAs('newPassword')
        }
    },
    // watch: {
    //     password() {
    //         this.password = this.password.substring(0, this.maxPassLength)
    //     }
    // },
    computed: {
        error() {
            return this.$store.getters['error/error']
        }
    },
    methods: {
        validationStatus(validation) {
            return typeof validation != undefined ? validation.$error: false
        },
        validatePassword() {
            var lower = document.getElementById("lower-char")
            var upper = document.getElementById("upper-char")
            var num = document.getElementById("number-char")
            var special = document.getElementById("special-char")
            var minChar = document.getElementById("minimum-character")

            if(this.newPassword.match(/[a-z]/)){
                lower.style.color = '#262A2C'
                lower.style.setProperty('--before-char', '#009934')
            } else {
                lower.style.color = '#919394'
                lower.style.setProperty('--before-char', '#E9EAEA')
            }

            if(this.newPassword.match(/[A-Z]/)){
                upper.style.color = '#262A2C'
                upper.style.setProperty('--before-char', '#009934')
            } else {
                upper.style.color = '#919394'
                upper.style.setProperty('--before-char', '#E9EAEA')
            }

            if(this.newPassword.match(/[0-9]/)){
                num.style.color = '#262A2C'
                num.style.setProperty('--before-char', '#009934')
            } else {
                num.style.color = '#919394'
                num.style.setProperty('--before-char', '#E9EAEA')
            }

            if(this.newPassword.match(/[!#@~*&^%)([\]\\+=?<>;:"`$_)-/',.|}{]+/)){
                special.style.color = '#262A2C'
                special.style.setProperty('--before-char', '#009934')
            } else {
                special.style.color = '#919394'
                special.style.setProperty('--before-char', '#E9EAEA')
            }

            if(this.newPassword.length >= 8){
                minChar.style.color = '#262A2C'
                minChar.style.setProperty('--before-character', '#009934')
            } else {
                minChar.style.color = '#919394'
                minChar.style.setProperty('--before-character', '#E9EAEA')
            }
        },
        showOldPassword() {
            if(this.oldPasswordState === 'password'){
                this.oldPasswordState = 'text'
            } else {
                this.oldPasswordState = 'password'
            }
        },
        showNewPassword() {
            if(this.newPasswordState === 'password'){
                this.newPasswordState = 'text'
            } else {
                this.newPasswordState = 'password'
            }
        },
        showNewPasswordConfirm() {
            if(this.confirmNewPasswordState === 'password'){
                this.confirmNewPasswordState = 'text'
            } else {
                this.confirmNewPasswordState = 'password'
            }
        },
        close() {
            this.oldPassword = null
            this.newPassword = null
            this.confirmNewPassword = null
            this.$root.$emit('bv::hide::modal', 'modal-change-password-v3')
        },
        submitBtn() {
            this.$store.dispatch("user/PasswordEdit",
                {
                    new_password: this.newPassword,
                    old_password: this.oldPassword
                })
                .then(_ => {
                    this.$toasted.show('New password created successfully.',{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000
                    })
                    this.oldPassword = null
                    this.newPassword = null
                    this.confirmNewPassword = null
                    this.$root.$emit('bv::hide::modal', 'modal-change-password-v3')
                })
                .catch(err => {
                    this.stateOldPassword = false
                    this.invalidOldPassword = typeof err == 'object' ? "Incorrect password. Please double-check your password, then try again." : "Incorrect password. Please double-check your password, then try again."
                })
        }
    }
}
</script>

<style scoped>
::-ms-reveal
{
    display: none !important;
}
@media (min-width: 576px) {
    .modal-dialog {
      width: 432px !important;
    }
}
</style>