<template>
    <b-modal id="modal-backup-code-2fa" no-close-on-backdrop hide-header hide-footer centered>
        <div class="d-flex flex-column">
            <span class="title-permission-auth">Backup codes</span>
            <span class="my-3 sub-permission-auth">Use backup codes  if you have issues receiving 6-digit verification code when signing  in. Please <span class="text-blue cursor-pointer" @click="download()">download</span> and keep them safe. </span>
            <div class="container-backup">
                <div class="row row-backup">
                    <div v-for="(code, idx) in getBackup" :key="idx" class="p-0 col-md-6">
                        <span class="card-backup d-flex align-items-center justify-content-center sub-permission-auth">{{code}}</span>
                    </div>
                </div>
            </div>
            <div class="mt-3 d-flex justify-content-end align-items-center">
                <span @click="close()" class="cancel-modal-auth cursor-pointer mr-3 main-color-api">Close</span>
                <button class="submit-modal-auth-btn ml-3" @click="regenerateCode()"><i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>Regenerate</button>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    data(){
        return {
            loading: false
        }
    },
    computed: {
        getBackup() {
            return this.$store.getters['mfa/backupCode']
        }
    },
    methods: {
        regenerateCode(){
            this.loading = true
            this.$store.dispatch('mfa/regenerateCode')
            .then((res)=>{
                this.loading = false
                this.$toasted.show(`${res.display_message}`,{ 
                    theme: "toasted-primary", 
                    position: "bottom-center", 
                    duration : 2000
                })
            })
        },
        close() {
            this.$bvModal.hide('modal-backup-code-2fa')
        },
        download(){
            this.$store.dispatch('mfa/downloadBackup')
            this.$toasted.show(`Downloading backup code`,{ 
                    theme: "toasted-primary", 
                    position: "bottom-center", 
                    duration : 2000,
                    containerClass : 'toast-snackbar'
                })
        }
    }
}
</script>

<style>
.container-backup{
    width: 100%;
    justify-content: space-between;
    display: flex;
}
.row-backup{
    margin: 0 !important;
    padding: 0 !important;
}
.card-backup{
    border: 1px solid #E9EAEA !important;
    padding: 16px;
    border-radius: 4px;
    width: 180px;
    margin: 8px 0;
}
</style>