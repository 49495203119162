<template>
  <b-modal id="modal-qr-2fa" no-close-on-backdrop hide-header hide-footer centered>
    <div class="d-flex flex-column">
        <span class="title-modal-qr">Enable two-factor authentication</span>
        <span class="sub-modal-qr my-3">Install the <span 
            class="text-blue cursor-pointer"
            >Google Authenticator</span> app on your mobile device. Scan the QR code or enter a setup key below.
        </span>
        <div class="img-modal-qr my-3">
            <img :src="getMFA ? getMFA.qr : ''" alt="">
        </div>
        <div class="form-secret-qr mt-3">
            <input
                type="text"
                :value="getMFA ? getMFA.secret : ''"
                disabled
                class="form-input-modal-qr"   
            >
            <img
                class="form-input-img-modal-qr cursor-pointer"
                src="@/assets/images/icon/fi-rr-copy-alt.svg"
                alt=""
                @click="getMFA ? copyCode(getMFA.secret) : ''"
                data-toggle="tooltip"
                title="Copy Secret Code"
            >
        </div>
        <!-- <pre>{{getMFA}}</pre> -->
        <span class="sub-modal-qr my-3">Type of key: Time based</span>
        <div class="mt-3 d-flex justify-content-end align-items-center">
            <span @click="back()" class="cancel-modal-auth cursor-pointer mr-3 main-color-api">Back</span>
            <button @click="next()" class="submit-modal-auth-btn ml-3">Next</button>
        </div>
    </div>
  </b-modal>
</template>

<script>
export default {
    computed: {
        getMFA() {
            return this.$store.getters['mfa/dataMFA']
        }
    },
    methods: {
        next() {
            this.$bvModal.show('modal-code-auth-2fa')
            this.$bvModal.hide('modal-qr-2fa')
        },
        back() {
            this.$bvModal.show('modal-enable-2fa')
            this.$bvModal.hide('modal-qr-2fa')
        },
        copyCode(value) {
            navigator.clipboard.writeText(value)
            // this.$toasted.show('Link copied.',{ 
            //     theme: "toasted-primary", 
            //     position: "bottom-center", 
            //     duration : 2000
            // })
        }
    }
}
</script>

<style>

</style>