<template>
  <b-modal id="modal-view-backup-2fa" hide-header hide-footer centered>
  <!-- <b-modal id="modal-enable-2fa" no-close-on-backdrop hide-header hide-footer centered> -->
    <div class="d-flex flex-column">
        <span class="title-enable-2fa">View backup codes</span>
        <span class="my-3 sub-enable-2fa">For security reasons, please enter your account password.</span>
        <form class="my-3" action=""  @submit.prevent="viewBackup">
            <div class="form-control-encrypt">
                <input
                    id="password-decrypt"
                    placeholder="Password"
                    :type="passwordState"
                    class="form-input-encrypt"
                    v-model.trim="$v.password.$model"
                    :class="{'is-invalid':passwordRequired || invalidPassword != null, 'is-valid':!$v.password.$invalid}">
                <img v-if="passwordState == 'password'" src="@/assets/images/icon/fi-rr-eye-close.svg" alt="" @click="showPassword()">
                <img v-else src="@/assets/images/icon/fi-rr-eye-open.svg" alt="" @click="showPassword()">
                <div class="d-flex flex-column invalid-feedback">
                    <!-- <span v-if="!$v.password.required">Password is required.</span> -->
                    <span v-if="passwordRequired != null">{{passwordRequired}}</span>
                    <span v-if="invalidPassword != null">{{invalidPassword}}</span>
                </div>
            </div>
        </form>
        <div class="mt-3 d-flex justify-content-end align-items-center">
            <span class="cancel-modal-auth cursor-pointer mr-3 main-color-api" @click="close()">Cancel</span>
            <button :disabled="this.password == '' || this.password == null" class="submit-modal-auth-btn ml-3" @click="viewBackup()">Next</button>
        </div>
    </div>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            password: null,
            passwordState: 'password',
            invalidPassword: null,
            passwordRequired: null
        }
    },
    validations: {
        password: {
            required
        }
    },
    methods: {
        showPassword() {
            if(this.passwordState === 'password'){
                this.passwordState = 'text'
            } else {
                this.passwordState = 'password'
            }
        },
        viewBackup() {
            var payload = {
                password: this.password
            }
            this.$store.dispatch("mfa/viewBackupCode", payload)
            .then(()=>{
                this.$bvModal.hide('modal-view-backup-2fa')
                this.$bvModal.show('modal-backup-code-2fa')
                this.password = ''
                this.invalidPassword = null
            })
            .catch((err) => {
                this.invalidPassword = err.data.display_message
            })
        },
        close() {
            this.$bvModal.hide('modal-view-backup-2fa')
            this.password = ''
            this.invalidPassword = null
            this.passwordRequired = null
        }
    },
    watch: {
        password() {
            if(this.password == '' || null){
                this.invalidPassword = null
                this.passwordRequired = 'Password is required'
            } else {
                this.passwordRequired = null
            }
        }
    }
    
}
</script>

<style>
.title-enable-2fa{
    color: #262A2C;
    line-height: 24px;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    letter-spacing: -0.02em;
}
.sub-enable-2fa{
    color: #262A2C;
    line-height: 20px;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    letter-spacing: -0.02em;
}
</style>