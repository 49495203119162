<template>
  <b-modal id="modal-code-auth-2fa" no-close-on-backdrop hide-header hide-footer centered>
    <div class="d-flex flex-column">
        <span class="title-permission-auth">Enable two-factor authentication</span>
        <span class="my-3 sub-permission-auth">Enter verification code generated by your Google Authenticator app.</span>
        <form action="" @submit.prevent="next">
            <input 
                type="number"
                placeholder="6-digit code"
                :class="this.invalidCode != null ? 'form-input-auth-code red-border-auth' : 'form-input-auth-code'"
                v-model="digit"
            >
        </form>
        <!-- <div> -->
        <div v-if="invalidCode != null">
            <span class="invalid-code-auth">Invalid code.</span>
        </div>
        <div class="mt-3 d-flex justify-content-end align-items-center">
            <span @click="back()" class="cancel-modal-auth cursor-pointer mr-3 main-color-api">Back</span>
            <button :disabled="this.digit == '' || this.digit == null" class="submit-modal-auth-btn ml-3" @click="next()"><i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>Enable</button>
        </div>
    </div>
  </b-modal>
</template>

<script>
export default {
    data() {
        return {
            digit: null,
            maxNumberLength: 6,
            invalidCode: null,
            loading: false
        }
    },
    computed: {
        getMFA() {
            return this.$store.getters['mfa/dataMFA']
        },
        email() {
            return this.$store.getters['user/email']
        }
    },
    methods: {
        back() {
            this.$bvModal.hide('modal-code-auth-2fa')
            this.$bvModal.show('modal-qr-2fa')
        },
        next() {
            var payload = {
                email: this.email,
                secret: this.getMFA.secret,
                token: this.digit
            }
            this.loading = true
            this.$store.dispatch('mfa/verifyGoogleAuth', payload)
            .then((res)=> {
                this.loading = false
                this.$toasted.show(`Two-factor authenticator enabled successfully.`,{ 
                    theme: "toasted-primary", 
                    position: "bottom-center", 
                    duration : 2000,
                    containerClass : 'toast-snackbar'
                })
                this.digit = null
                this.$store.dispatch('user/getLimit')
                .then(()=> {
                    this.$bvModal.hide('modal-code-auth-2fa')
                    this.$bvModal.show('modal-backup-code-2fa')
                })
                .catch((err)=> {})
            })
            .catch((err) => {
                this.loading = false
                this.invalidCode = err
            })
        }
    },
    watch: {
        digit() {
            this.digit = this.digit.substring(0, this.maxNumberLength)
            if(this.digit == '' || this.digit == null){
                this.invalidCode = null
            }
        }
    }
}
</script>

<style>
.form-input-auth-code[type="number"]::-webkit-outer-spin-button,
.form-input-auth-code[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.form-input-auth-code[type="number"] {
    -moz-appearance: textfield;
}
.form-input-auth-code{
    border: 1px solid #E9EAEA;
    outline: none;
    border-radius: 4px;
    color: #262A2C;
    line-height: 20px;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    letter-spacing: -0.02em;
    padding: 16px;
    width: 100%;
}
.form-input-auth-code::placeholder{
    color: #919394;
    line-height: 20px;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    letter-spacing: -0.02em;
}
.title-permission-auth{
    color: #262A2C;
    line-height: 24px;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    letter-spacing: -0.02em;
}
.sub-permission-auth{
    color: #262A2C;
    line-height: 20px;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    letter-spacing: -0.02em;
}
.red-border-auth{
    border: 1px solid #D42020;
}
.invalid-code-auth{
    color: #D42020;
}
</style>